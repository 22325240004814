import { ReactElement } from "react";
import {
  PageContextClient as PageContextBuiltInClient,
  PageContextServer as PageContextBuiltInServer,
} from "vike/types";

type Page = () => ReactElement;

export type PageContextCustom = {
  Page: Page;
  urlPathname: string;
};

export type PageContextServer = PageContextBuiltInServer<Page> &
  PageContextCustom;
export type PageContextClient = PageContextBuiltInClient<Page> &
  PageContextCustom & {
    metas: {
      prevMeta?: PostMeta;
      meta?: PostMeta;
      nextMeta?: PostMeta;
    };
  };

export type PageContext = PageContextClient | PageContextServer;

export type PostMeta = {
  url: string;
  title: string;
  subtitle: string;
  date: Date;
  tags?: string[];
  draft?: boolean;
};

export const rawDateToDate = (rawDate: string) => {
  const [date, time] = rawDate.split(" ");
  return new Date(`${date}T${time}:00`);
};
