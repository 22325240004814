import { Box, Link } from "@chakra-ui/react";

import { PostMeta } from "../renderer/types";
import { Date } from "./Date";
import { Text } from "./Text";
import { useColors } from "./useColors";

export const HomePost = ({ meta }: { meta: PostMeta }) => {
  const { url, title, date, subtitle } = meta;
  const { linkBackground, subtitleColor } = useColors();

  return (
    <Box>
      <Link
        href={url}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        h="full"
        p={4}
        _hover={{
          textDecoration: "none",
          bg: linkBackground,
        }}
        _focus={{ bg: linkBackground }}
      >
        <Text textStyle="sans" fontSize="sm" color={subtitleColor}>
          <Date date={date} />
        </Text>
        <Text fontWeight="bold" wrap="balance">
          {title}
        </Text>
        <Text fontStyle="italic" wrap="balance">
          {subtitle}
        </Text>
      </Link>
    </Box>
  );
};
